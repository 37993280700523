<template>
  <div class="vca-column">
    <vca-accordion :header="$t('faqs.goldeimer.goldeimer.header')">
      <div slot="body" v-html="$t('faqs.goldeimer.goldeimer.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.goldeimer.vca.header')">
      <div slot="body" v-html="$t('faqs.goldeimer.vca.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.goldeimer.tasks.header')">
      <div slot="body" v-html="$t('faqs.goldeimer.tasks.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.goldeimer.apply.header')">
      <div slot="body" v-html="$t('faqs.goldeimer.apply.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.goldeimer.age.header')">
      <div slot="body" v-html="$t('faqs.goldeimer.age.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.goldeimer.get_there.header')">
      <div slot="body" v-html="$t('faqs.goldeimer.get_there.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.goldeimer.food.header')">
      <div slot="body" v-html="$t('faqs.goldeimer.food.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.goldeimer.ticket.header')">
      <div slot="body" v-html="$t('faqs.goldeimer.ticket.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.goldeimer.shit.header')">
      <div slot="body" v-html="$t('faqs.goldeimer.shit.body')" />
    </vca-accordion>
  </div>
</template>
<script>
export default {
  name: "FaqsGoldeimer",
};
</script>
